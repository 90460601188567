<template>
  <section>
    <div>
      LOCATION ELEMENT FOR ID
    </div>
  </section>
</template>

<script>
export default {
  name: 'viewLocation'
}
</script>

<style scoped>

</style>
